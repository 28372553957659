export const APP_URLS = {
    LOGIN: '/login',
    INDEX: '/',
    STARTPAGE: '/start',
    EDI_MESSAGES: '/edi-dashboard'
}

export const API_URL = {
    API_URL: 'https://backend-dev.augmenticom.fr'
}

export const ENDPOINTS = {
    LOGIN: '/auth/token',
    TOKEN_INTROSPECT: '/auth/token/introspect',
    USER_INFO: '/auth/userinfo',
    ROLES: '/roles',
    MESSAGES: '/edi/messages'
}

export const TRACKING = {
    LOG: false
}

export const COLUMN_TYPES = {
    STATUS: 'STATUS'
}

export const ERRORS = {
    DATA_FETCH_FAILED: 'Cannot fetch data',
    ONE_OR_MORE_FIELDS_VALIDATION_FAILED: 'One of more fields failed to validate'
}

export const ACCEPTED_DATE_FORMAT = ['DD/MM/YYYY', 'YYYY-MM-DD[T]HH:mm:ss']

export default {
    DEFAULT_DATE_FORMAT: process.env.REACT_APP_DEFAUT_DATEFORMAT ? process.env.REACT_APP_DEFAUT_DATEFORMAT : 'DD/MM/YYYY'
}
