import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {getModuleEnumByLabel} from "./constants/enums/moduleEnum"
import Layout from './components/Layout'
import EventService from './services/EventService'
import track, { useTracking } from 'react-tracking'
import { TRACKING } from 'utilities/constants'
import { useEffect, useState } from "react";
import LoginService from 'services/LoginService'
import LoginPage from 'pages/login/login'
import { useLocation } from 'react-router-dom';

const isAuth = window.localStorage.getItem('isAuthenticated')
const userModules = window.localStorage.getItem('modules')
const isActive = window.localStorage.getItem('isActive')
const token = window.localStorage.getItem('token')
const id = window.localStorage.getItem('id')

export const App = ({ component: Component, rest }) => {
    const [isValid, setIsValid] = useState(true);
	const location = useLocation();

	useEffect(() => {
		const timer = setTimeout(() => {
			getTokenValidation();
		  }, 1000);
	}, [location.pathname]);
	const getTokenValidation = (event) => {
			LoginService.validerJwt(token,id)
			.then((response) => {
				setIsValid(response.data);
			})
			.catch((error) => {
				setIsValid(false);
			});
	
	};	
    const { Track } = useTracking(
        { app: 'augmenticom-client' },
        {
            dispatch: (data) => {
                if(TRACKING.LOG){
                    if (data.status == undefined || data.status == '' || data.status == null) {
                        EventService.saveEvents({
                            app: data.app,
                            token: token,
                            funComponent: data.funComponent,
                            event: data.event,
                            status: '200'
                        })
                    } else {
                        EventService.saveEvents({
                            app: data.app,
                            token: token,
                            funComponent: data.funComponent,
                            event: data.event,
                            status: data.status
                        })
                    }
                }
            }
        }
    )
    return (
        <Track>
            <Route
                {...rest}
                render={(routeProps) => {
                    let ModulesJSON = JSON.parse(userModules)
                    let modules = ''
                    if (ModulesJSON !== null) {
                        Object.keys(ModulesJSON).forEach((name) => {
                            let module = getModuleEnumByLabel(ModulesJSON[name].module.libelle)
                            if (module === 'Volumetrie' || module === 'KPI') module = 'Statistique'
                            if (module === 'Role' || module === 'Utilisateur') module = 'Parametre'
                            modules = modules + module + ','
                            let sousModules = ModulesJSON[name].sousModule
                            if (sousModules !== null) {
                                sousModules.forEach((sousModule) => {
                                    modules = modules + getModuleEnumByLabel(sousModule.libelle) + ','
                                })
                            }
                        })
                    }
                    modules = modules + 'Home,UserDetails,Documentation'
                    
                    if (isAuth && isValid) {
                        if (isActive === 'true') {
                            if (modules.includes(Component.name)) {
                                return (
                                    <Layout>
                                        <Component {...routeProps} />
                                    </Layout>
                                )
                            } else {
                                return <Redirect to="/404" />
                            }
                        } else {
                            return <Redirect to="/modifyPassword" />
                        }
                    } else {
                        return <LoginPage />
                    }
                }}
            />
        </Track>
    )
}
