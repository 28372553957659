import { ArrowRightOutlined, LoadingOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip, Form, Select, Space, Input, AutoComplete, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'
import track, { useTracking } from 'react-tracking'


const TransfertConfigCard = ({ jsonValues }) => {

const body = [];
const generateBody = (propertyName, value)=>{
    if(value===null||value===undefined){return;}
    if(typeof(value)==="object"){
        Object.keys(value).map(key=>{
            generateBody(key,value[key]);
        })
    }
    else{
        body.push(<div>{propertyName+" : "+value}</div>);
        body.push(<br/>);
    }
}
generateBody("main body",jsonValues)
    return (
<div style={{
    flex:1,
    // backgroundColor:"#f0f", 
    // height:100, 
    // width:100
    }}>
    {body}
</div>
        

    )
}

export default TransfertConfigCard