import React from "react";

import { PlusOutlined } from "@ant-design/icons"
import { Button, Form, Input, message } from "antd"
import Text from "antd/lib/typography/Text"
import { useEffect, useRef, useState } from "react"
import WidgetUIService from "services/WidgetUIService"
import DynamicForm from "./DynamicForm"



function CustomPopUp({ type, clickOut, height, implantHost, implantPort, resetAccounts, resetFolders, resetDefinitions, resetParams, deleteDefinitionSignal, index1, index2, index3, index4 }) {

    const success = (str) => {
        message.success(str);
    };
    const error = (str) => {
        message.error(str);
    };

    useEffect(() => {

    }, [])

    let formItems = [];
    switch (type) {
        case "Add account":
            formItems.push(
                <Form.Item label="Account name : " name="account">
                    <Input placeholder="Enter account name" />
                </Form.Item>
            );
            formItems.push(
                <Form.Item label="Password : " name="password">
                    <Input placeholder="Enter password" type="password" />
                </Form.Item>
            );
            break;
        case "Add folder":
            formItems.push(
                <Form.Item label="Folder path : " name="folder">
                    <Input placeholder="Enter folder path" />
                </Form.Item>
            );
            break;
        case "Add definition":
            formItems.push(
                <Form.Item label="Début de définition : " name="value_from">
                    <Input type="date" />
                </Form.Item>
            );
            break;
        case "Delete definition":
            break;

        case "Add params":
            formItems.push(
                <DynamicForm />
            );

    }

    const onModify = (value) => {
        console.log(JSON.stringify(value))
        console.log("type : " + type)
        switch (type) {
            case "Add account":
                WidgetUIService.addAccount(value.account, value.password, implantHost, implantPort)
                    .then((res) => {
                        console.log("response add account : " + JSON.stringify(res));
                        if (res.POURSUITE) { success("Account added"); resetAccounts(); }
                        else { error(res.ERROR.MESSAGE) }

                    })
                    .catch(e => { error(e); })
                break;
            case "Add folder":
                WidgetUIService.addFolder(value.folder, index1, implantHost, implantPort)
                    .then((res) => {
                        console.log("response add folder : " + JSON.stringify(res));
                        if (res.POURSUITE === false) { error(res.ERROR.MESSAGE); console.log("erreur : " + res.ERROR.MESSAGE) }
                        else { success("Folder added"); resetFolders(); }

                    })
                    .catch(e => { error(e); })
                break;
            case "Add definition":
                WidgetUIService.addDefinition(value.value_from, index1, index2, implantHost, implantPort)
                    .then((res) => {
                        console.log("response add definition : " + JSON.stringify(res));
                        if (res.POURSUITE === false) { error(res.ERROR.MESSAGE); console.log("erreur : " + res.ERROR.MESSAGE) }
                        else { success("Definition added"); resetDefinitions(); }

                    })
                    .catch(e => { error(e); })
                break;
            case "Delete definition":
                console.log("response delete definition : ")
                WidgetUIService.deleteDefinition(index1, index2, index3, implantHost, implantPort)
                    .then((res) => {
                        console.log("response delete definition : " + JSON.stringify(res));
                        if (res.POURSUITE === false) { error(res.ERROR.MESSAGE); console.log("erreur : " + res.ERROR.MESSAGE) }
                        else { success("Definition deleted"); resetDefinitions(); deleteDefinitionSignal(); }

                    })
                    .catch(e => { error(e); })
                break;
            case "Add params":
                WidgetUIService.addParams(value, index1, index2, index3, implantHost, implantPort)
                    .then((res) => {
                        console.log("response add params : " + JSON.stringify(res));
                        if (res.POURSUITE === false) { error(res.ERROR.MESSAGE); console.log("erreur : " + res.ERROR.MESSAGE) }
                        else { success("Params added"); resetParams(); }

                    })
                    .catch(e => { error(e); })
                break;
        }
    }

    const classicForm =
        
        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}><Text style={{ height: 50, fontSize: 32 }}>{type}</Text>

            <Form
                method="GET"
                name="form-popup"
                onFinish={onModify}
                layout={(type === "Add params") ? "horizontal" : "vertical"}
                style={{
                    margin: "auto",
                    display: "flex",
                    flex: 1,
                    width: "100%"
                }}
                labelCol={{
                    span: 6,
                }}
            >
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>

                    <div style={{ overflowY: 'auto', height:320,
                        //  backgroundColor:"#f0f" 
                         }} >
                        {formItems}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", flex: 1 }}>
                        <Button
                            type="secondary"
                            id="btn-cancel"
                            style={{ backgroundColor: 'white', color: '#3CB371' }}
                            size="large"
                            onClick={clickOut}
                        >
                            Annuler
                        </Button>
                        <Form.Item
                        >
                            <Button
                                type="primary"
                                id="btn-modify"

                                style={{ backgroundColor: '#3CB371', color: 'white' }}
                                htmlType="submit"
                                size="large"
                            >
                                {type.split(" ")[0]}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>

    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ height: 100, display: "flex" }} onClick={clickOut}>

            </div>
            <div style={{ display: "flex", flexDirection: "row", height: height }}>
                <div style={{ flex: 1 }} onClick={clickOut}>

                </div>
                <div style={{ flex: 8, backgroundColor: "#F0F2F5", borderRadius: 20, border: "2px solid #3CB371", height: height, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{
                        height: "90%", width: "80%", display: "flex", flexDirection: "column", alignItems: "center"
                        // , backgroundColor:"#ff0" 
                    }}>
                        {classicForm}
                    </div>
                </div>
                <div style={{ flex: 1 }} onClick={clickOut}>

                </div>
            </div>
            <div style={{ flex: 1, display: "flex" }} onClick={clickOut}>

            </div>

        </div>
    )
}
export default CustomPopUp