import http from "../utilities/http-common";

class FactureService {
  static fetchFactureDetails(params = {}) {
    const { societe, agence } = params;
    return http.get(`/api/v1/facture?societe=${societe || ''}&agence=${agence || ''}&codeTiers=${params.codeTiers || ''}`);
  }

  static fetchSocieteOptions() {
    return http.get("/api/v1/facture/societes");
  }

  static fetchAgenceOptions() {
    return http.get("/api/v1/facture/agences");
  }
  static fetchCodeTierOptions() {
    return http.get("/api/v1/facture/codeTiers");
  }
  static fetchGroupFormats() {
    return http.get("/api/v1/facture/groupFormats");
  }

  static updateFacture(id, data) {
    return http.put(`/api/v1/facture/update/${id}`, null, { params: data });
  }

  static addFacture(data) {
    return http.post("/api/v1/facture/add", null, { params: data });
  }
}

export default FactureService;
