import http from 'utilities/http-common'



const getImplants = (idClient) => {

    return http.get('/api/v1/implants/' + idClient);
}

const getImplantConfig = async (host, port, password, user) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "MODULE": "ACCOUNT",
                "ACTION": "LIST"
            })
        };
        try {
            let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);

            res = await res.json();
            res = res.FFST.RESPONSE.ACCOUNT
            // console.log("res : " + JSON.stringify(res));
            return res;

        }
        catch (e) {
            console.log("error : " + e)
            return ("error : " + e)
        }
}

const getAccounts = async (host, port) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "MODULE": "ACCOUNT",
            "ACTION": "LIST"
        })
    };
    try {
        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);

        res = await res.json();
        res = res.FFST.RESPONSE.ACCOUNT
        // console.log("res : " + JSON.stringify(res));
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const getFolders = async (accountKey, host, port) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "MODULE": "KEY",
            "ACTION": "LIST",
            "PARAM": {
                "ID_ACCOUNT": accountKey
            }
        })
    };
    try {
        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);

        res = await res.json();
        res = res.FFST.RESPONSE.KEY
        // console.log("FOLDERS : " + JSON.stringify(res));
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}
const getDefinitions = async (accountKey, folderKey, host, port) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "MODULE": "VALUE",
            "ACTION": "LIST",
            "PARAM": {
                "ID_ACCOUNT": accountKey,
                "ID_KEY": folderKey
            }
        })
    };
    try {
        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);

        res = await res.json();
        res = res.FFST.RESPONSE.VALUE
        // console.log("VALUE : " + JSON.stringify(res));
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}
const addAccount = async (account, password, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "MODULE": "ACCOUNT",
            "ACTION": "ADD",
            "PARAM": {
                "USERNAME": account,
                "PASSWORD": password
            }
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions)
        // console.log("success");
        res = await res.json();
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const addFolder = async (folder, account_key, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "MODULE": "KEY",
            "ACTION": "ADD",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "KEY": folder
            }
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions)
        // console.log("success");
        res = await res.json();
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const addDefinition = async (config_from, account_key, folder_key, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "VALUE",
            "ACTION": "ADD",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "ID_KEY": folder_key,
                "VALUE": {
                    "VALUE_FROM": config_from,
                    "VALUE_CONTENT": []
                }
            }
        }
        const raw = JSON.stringify(param);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log("raw  : " + raw);
        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions)
        // console.log("success");
        res = await res.json();
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}



const deleteDefinition = async (account_key, folder_key, value_key, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "VALUE",
            "ACTION": "DELETE",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "ID_KEY": folder_key,
                "ID_VALUE": value_key
            }
        }
        const raw = JSON.stringify(param);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log("raw  : " + raw);
        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions)
        // console.log("success");
        res = await res.json();
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const addParams = async (paramsJSON, account_key, folder_key, definition_key, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "VALUE",
            "ACTION": "LIST",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "ID_KEY": folder_key
            }
        }
        let raw = JSON.stringify(param);
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);
        res = await res.json();

        let content = res.FFST.RESPONSE.VALUE.filter(x => x.value_id === definition_key)[0].value_content;
        // console.log("content before add : " + content);
        content = content.split("]");

        let parsedJSON = {
            prot: paramsJSON.prot,
            param: {}
        };

        for (const property in paramsJSON) {

            if (property === "prot") { }
            else if (property === "host") {
                parsedJSON.param[property] = paramsJSON[property].host;
            }
            else {
                parsedJSON.param[property] = paramsJSON[property];
            }
        }
        if (content[0].length === 1) {
            content = "[" + JSON.stringify(parsedJSON) + "]";
        }
        else {
            const varToAdd = "," + JSON.stringify(parsedJSON);
            content[content.length - 1] = varToAdd;
            content.push("]");
            content = content.join("");
        }

        // console.log("content after add : " + content);
        param = {
            "MODULE": "VALUE",
            "ACTION": "UPDATE",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "ID_KEY": folder_key,
                "ID_VALUE": definition_key,
                "VALUE": {
                    "VALUE_CONTENT": content
                }
            }
        };

        raw = JSON.stringify(param);
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);
        res = await res.json();
        return res;

    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}
const getParams = async (account_key, folder_key, definition_key, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "VALUE",
            "ACTION": "LIST",
            "PARAM": {
                "ID_ACCOUNT": account_key,
                "ID_KEY": folder_key
            }
        }
        let raw = JSON.stringify(param);
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);
        res = await res.json();

        let content = JSON.parse(res.FFST.RESPONSE.VALUE.filter(x => x.value_id === definition_key)[0].value_content);
        return content;


    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const getHistory = async (nb_results, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "HISTORY",
            "ACTION": "LIST",
            "FILTER": {
                "NB_REC": nb_results
            }
        }
        let raw = JSON.stringify(param);
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);
        res = await res.json();
        return res.RESPONSE.HISTORY;
    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

const getFilteredHistory = async (nb_results, id_account, id_folder, host, port) => {


    try {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        let param = {
            "MODULE": "HISTORY",
            "ACTION": "LIST",
            "FILTER": {
                "NB_REC": nb_results,
                "ID_ACCOUNT": id_account,
                "ID_KEY": id_folder
            }
        }
        let raw = JSON.stringify(param);
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        let res = await fetch(`http://${host}:${port}/ws/ffst/`, requestOptions);
        res = await res.json();
        return res.RESPONSE.HISTORY;
    }
    catch (e) {
        console.log("error : " + e)
        return ("error : " + e)
    }


}

// const getConfig = async (implants) => {
//     let configs = [];
//     for (let i = 0; i < implants.length; i++) {
//         const requestOptions = {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//                 user: implants[i].user,
//                 password: implants[i].password,
//                 port: implants[i].port,
//                 host: implants[i].host
//             })
//         };
//         try {
//             const res = await fetch("http://localhost:3001/api/config", requestOptions);
//             if (res.status == 200) { configs[i] = await res.json(); }
//         }
//         catch (e) {
//             console.log("error : " + e)
//         }
//     }
//     return configs;
// }

// const setConfig = async (implant, params) => {

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//             user: implant.user,
//             password: implant.password,
//             port: implant.port,
//             host: implant.host,
//             newValues: params
//         })
//     };
//     try {
//         const res = await fetch("http://localhost:3001/api/config/modify", requestOptions);
//         if (res.status == 200) { return await res.json(); }
//     }
//     catch (e) {
//         console.log("error : " + e)
//     }

// }

export default {
    getImplants,
    getImplantConfig,
    // getConfig,
    // // setConfig,
    getAccounts,
    getFolders,
    getDefinitions,
    addAccount,
    addFolder,
    addDefinition,
    deleteDefinition,
    addParams,
    getParams,
    getHistory,
    getFilteredHistory
}
