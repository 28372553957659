import { ClearOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip, Form, Select, Space, Input, AutoComplete, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'
import track, { useTracking } from 'react-tracking'


const ImplantsFilterBar = ({ onFilter, onReset, host, port }) => {
    const isClickable = (host && port);
    const [initOptionsAccount, setInitOptionsAccount] = useState([]);
    const [initOptionsFolder, setInitOptionsFolder] = useState([]);
    const [optionsAccount, setOptionsAccount] = useState([]);
    const [optionsFolder, setOptionsFolder] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getPanelValue = (searchText, initOptions) =>
        !searchText ? initOptions : initOptions.filter(x => {
            const textToSearch = searchText.toLowerCase();
            const textToCompare = x.label.toLowerCase();
            // console.log("for search : "+searchText)
            // console.log(x)
            if (textToSearch.length > textToCompare.length) { return false; }
            for (let i = 0; i < textToSearch.length; i++) {
                if (textToSearch[i] !== textToCompare[i]) { return false; }
            }
            return true;
        });
    const findAccount = (text) => {
        // console.log("text : "+text);
        const account = initOptionsAccount.filter(x => x.value.toLowerCase() === text.toLowerCase());
        // console.log("account list : "+JSON.stringify(initOptions))
        if (account.length > 0) {
            // console.log("account find : " + JSON.stringify(account[0]))
            WidgetUIService.getFolders(account[0].id, host, port)
                .then((folders) => {
                    setInitOptionsFolder(folders.map(x => { return { value: x.key_content, label: x.key_content, id: x.key_id } }));
                });
        }
        else {
            setInitOptionsFolder([]);
        }
    }

    useEffect(() => {
        if (isClickable) {
            WidgetUIService.getAccounts(host, port)
                .then((accounts) => {
                    setInitOptionsAccount(accounts.map(x => { return { value: x.account, label: x.account, id: x.id } }));
                });
        }
    }, [host, port])



    return (

        <>

            <div className="ant-row" style={{ maxWidth: 1600 }}>
                <Form
                    method="GET"
                    name="select-implant"
                    layout="inline"
                    onFinish={async (value) => {
                        setIsLoading(true);
                        await onFilter({ ...value, initOptionsAccount, initOptionsFolder })
                        setIsLoading(false);
                    }}
                    // labelCol={{
                    //     span: 6,
                    // }}
                    style={{
                        maxWidth: 1200,
                        margin: "auto",
                    }}
                >
                    <Form.Item
                        name={"account"}
                        label={"Compte :"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <AutoComplete
                            options={optionsAccount}
                            style={{
                                width: 200,
                            }}
                            onFocus={(text) => {
                                setOptionsAccount(getPanelValue(text.target.value, initOptionsAccount));
                            }}
                            onSelect={(text) => {
                                setOptionsAccount(getPanelValue(text, initOptionsAccount));
                                findAccount(text);
                            }}
                            onSearch={(text) => {
                                setOptionsAccount(getPanelValue(text, initOptionsAccount));
                                findAccount(text);
                            }}
                            placeholder="entrez compte"
                        />

                    </Form.Item>
                    <Form.Item
                        name={"folder"}
                        label={"Dossier :"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <AutoComplete
                            options={optionsFolder}
                            style={{
                                width: 200,
                            }}
                            onFocus={(text) => setOptionsFolder(getPanelValue(text.target.value, initOptionsFolder))}
                            onSearch={(text) => setOptionsFolder(getPanelValue(text, initOptionsFolder))}
                            placeholder="entrez dossier surveillé"
                        />

                    </Form.Item>
                    <Form.Item
                        name={"config_from"}
                        label={"Du :"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Input
                            type="date"
                            size="medium"
                        />

                    </Form.Item>
                    <Form.Item
                        name={"config_to"}
                        label={"Au :"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Input
                            type="date"
                            size="medium"
                        />

                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            id="btn-submit"
                            style={{ backgroundColor: '#3CB371', color: 'white' }}
                            htmlType="submit"
                            block
                            size="large"
                            disabled={!isClickable}
                            icon={<FilterOutlined />}
                        >
                            Filtrer
                        </Button>
                    </Form.Item>
                    <Button
                        type="secondary"
                        id="btn-cancel"
                        style={{ backgroundColor: 'white', color: '#3CB371' }}
                        size="large"
                        disabled={!isClickable}
                        icon={<ClearOutlined />}
                        onClick={
                            async () => {
                                setIsLoading(true);
                                await onReset();
                                setIsLoading(false);
                            }
                        }
                    >
                        Réinitialiser
                    </Button>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        width: 30
                    }}>
                        {(isLoading) && <Spin
                            size="large"
                            indicator={<LoadingOutlined spin />}
                        />}
                    </div>
                </Form>
            </div>
        </>

    )
}

export default ImplantsFilterBar