import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Menu, message } from 'antd'
import { HomeOutlined, UserOutlined, ProfileOutlined, ImportOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import '../../static/less/app.less'
import { getModuleEnumByLabel, getModuleIcon } from '../../constants/enums/moduleEnum'
import ModulesProvider from '../../utilities/modules'
import UserService from 'services/UserService'
import track, { useTracking } from 'react-tracking'

ModulesProvider()
const { Sider } = Layout
const { SubMenu } = Menu

const App = () => {
    const { trackEvent } = useTracking()
    const [collapsed, setCollapsed] = useState(false)
    const location = useLocation()
    const [dataMenu, setDataMenu] = useState([])
    const [menu, setMenu] = useState([])

 
    useEffect(() => {
        try {
            const checkRole = window.localStorage.getItem('modules')
            const parsedData = checkRole ? JSON.parse(checkRole) : []
            setDataMenu(parsedData)
        } catch (error) {
            console.error('Erreur lors du chargement des modules :', error)
            setDataMenu([])
        }
    }, [])

    const buildMenu = () => {
        if (menu.length > 0 || !Array.isArray(dataMenu) || dataMenu.length === 0) {
            return menu
        }

        const newMenu = []
        let statistiqueNoShowed = true
        let parametreNoShowed = true

        dataMenu.forEach((module) => {
            const { sousModule, module: moduleInfo } = module
            const moduleName = moduleInfo.libelle

           
            if (moduleName.startsWith('ad_')) return

          
            if (['Statistique(s)', 'Volumétrie', 'K.P.I.'].includes(moduleName) && statistiqueNoShowed) {
                newMenu.push(
                    <Menu.Item key="Statistiques" icon={getModuleIcon('Statistique(s)')} className="menu-item-color">
                        Statistique(s)
                        <Link to={`/${getModuleEnumByLabel('Statistique(s)')}`} />
                    </Menu.Item>
                )
                statistiqueNoShowed = false
                return
            }

            if (['Paramètre(s)', 'Rôle(s)', 'Utilisateur(s)'].includes(moduleName) && parametreNoShowed) {
                newMenu.push(
                    <Menu.Item key="Paramètres" icon={getModuleIcon('Paramètre(s)')} className="menu-item-color">
                        Paramètre(s)
                        <Link to={`/${getModuleEnumByLabel('Paramètre(s)')}`} />
                    </Menu.Item>
                )
                parametreNoShowed = false
                return
            }

          
            if (sousModule && sousModule.length > 0) {
                const modulesChildren = sousModule
                    .filter((sm) => !sm.libelle.startsWith('ad_'))
                    .map((sm) => (
                        <Menu.Item key={sm.libelle}>
                            <NavLink exact to={`/${getModuleEnumByLabel(sm.libelle)}`}>
                                {sm.libelle}
                            </NavLink>
                        </Menu.Item>
                    ))

                newMenu.push(
                    <SubMenu key={moduleName} icon={getModuleIcon(moduleName)} title={moduleName}>
                        {modulesChildren}
                    </SubMenu>
                )
            } else {
                newMenu.push(
                    <Menu.Item key={moduleName} icon={getModuleIcon(moduleName)} className="menu-item-color">
                        {moduleName}
                        <Link to={`/${getModuleEnumByLabel(moduleName)}`} />
                    </Menu.Item>
                )
            }
        })

        setMenu(newMenu)
        return newMenu
    }

    const onCollapse = (collapsed) => setCollapsed(collapsed)

    const handleLogout = () => {
        UserService.logOutUser()
            .then(() => {
                trackEvent({ funComponent: 'Logout', event: 'Logout-clicked' })
                message.success('Vous êtes déconnecté avec succès')
            })
            .catch((error) => {
                const status = error?.response?.data?.status
                if (status === 403) {
                    message.success('Vous êtes déconnecté avec succès')
                } else {
                    message.error('Une erreur est survenue lors de la déconnexion')
                }
            })
            .finally(() => {
                window.localStorage.clear()
                window.location.replace('/login')
            })
    }

    const primaryColor = localStorage.getItem('primary_color') || '#3CB371'

    return (
        document.getElementById('root')?.style.setProperty('--primary-color', primaryColor ? primaryColor : '#3CB371'),
        <>
            <Sider
                breakpoint="lg"
                theme="light"
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                style={{ '--primary-color': primaryColor }}
            >
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={localStorage.getItem('logo') || '../img/logo1.png'}
                        alt="logo1"
                        width="70%"
                        style={{ marginTop: '10%' }}
                    />
                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[location.pathname]}
                    onSelect={(key) => trackEvent({ funComponent: 'Menu', event: `Menu-${key.key}-clicked` })}
                >
                    <Menu.Item key="Home" className="menu-item-color">
                        <HomeOutlined />
                        <span>Home</span>
                        <Link to="/" />
                    </Menu.Item>
                    {buildMenu()}
                    <SubMenu
                        key="Profile"
                        icon={<ProfileOutlined />}
                        title={localStorage.getItem('name')}
                        className="menu-item-color"
                    >
                        <Menu.Item key="Profile">
                            <NavLink exact to="/profile">
                                <UserOutlined /> Gestion de Profil
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="Deconnexion" onClick={handleLogout}>
                            <ImportOutlined /> Deconnexion
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        </>
    )
}

export default App
