import React from "react";

import { PlusOutlined } from "@ant-design/icons"
import { useState } from "react"

function ButtonAdd({ onClick, dimensions }) {
    const [hovered, setHovered] = useState(false)
    const [clicked, setClicked] = useState(false)


    const customOnClick = () => {
        setClicked(true);
        let timeLeft = 0.3;
        const intervalId = setInterval(() => {
            timeLeft -= 0.1
            if (timeLeft <= 0) {
                setClicked(false);
                clearInterval(intervalId)
            }
        }, 100);
        onClick();
    }
    const startOver = () => {
        if (!hovered) { setHovered(true) }
    }
    const endOver = () => {
        if (hovered) { setHovered(false) }
    }

    return (
        <div>
            <button style={{
                width: dimensions,
                height: dimensions,
                // position: "relative",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                backgroundColor: hovered ? "#eee9" : "transparent",
                borderColor: "transparent",
                cursor: "pointer",

            }}
                onClick={customOnClick}
                onMouseOver={startOver}
                onMouseLeave={endOver}

            >

                <PlusOutlined style={{
                    // position: "absolute",
                    // top: -Math.round(dimensions / 20),
                    // left: -Math.round(dimensions / 20),
                    fontSize: dimensions + 'px',
                    color: clicked ? '#3CB371' : hovered ? "#999" : "#bbb"
                }} />

            </button>
        </div>
    )
}
export default ButtonAdd