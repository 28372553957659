import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

function CustomTableLayout({ items, dimensions, rowHeight, vDivider, hDivider }) {
    const divRef = useRef(null);
    const [divWidth, setDivWidth] = useState(1200);

    useEffect(() => {
        function throttle(f, delay) {
            let timer = 0;
            return function (...args) {
                clearTimeout(timer);
                timer = setTimeout(() => f.apply(this, args), delay);
            }
        }

        const handleResize = (entries) => {
            for (let entry of entries) {
                const newWidth = entry.contentRect.width;
                if (newWidth !== divWidth) {
                    setDivWidth(newWidth);
                }
            }
        };
        

        const resizeObserver = new ResizeObserver(throttle(handleResize, 300));
        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                resizeObserver.unobserve(divRef.current);
            }
            resizeObserver.disconnect();
        };
    }, []);

    const verticalSep = (
        <div style={{ display: "flex", width: "7px", justifyContent: "center" }}>
            <div style={{ display: "flex", backgroundColor: '#3CB371', width: "1px", height: rowHeight }} />
        </div>
    );

    const horizontalSep = (
        <div style={{ display: "flex", height: "10px", alignItems: "center" }}>
            <div style={{ display: "flex", flex: 1, backgroundColor: '#3CB371', height: "2px" }} />
        </div>
    );

    const rows = [];
    let row = [];
    let width = 0;

    items.forEach((item, i) => {
        row.push(item);
        width += dimensions[i] + 7;
        if (i === items.length - 1 || width + dimensions[i + 1] > divWidth) {
            rows.push([...row]);
            row = [];
            width = 0;
        }
    });

    const rowsComponents = rows.map((line, j) => {
        const lineWithSep = line.flatMap((item, i) => (i !== line.length - 1 ? [item, verticalSep] : [item]));
        return (
            <div key={j} style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                {lineWithSep}
            </div>
        );
    });

    return (
        <div ref={divRef} style={{ flex: 1 }}>
            {rowsComponents.flatMap((row, i) => (i !== rowsComponents.length - 1 ? [row, horizontalSep] : [row]))}
        </div>
    );
}

export default CustomTableLayout;
