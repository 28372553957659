import { Alert, Button, Card, Form, Checkbox, Input, message, Switch, Tabs, Row, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'
const { Option } = Select;

const jsonSchema = {
    FOLDER: {
        "title": "Paramètres de connexion",
        "description": "Détail des paramètres nécessaires à la bonne exécution",
        "type": "object",
        "properties": {
            "path": {
                "title": "Dossier de destination",
                "description": "Préciser le chemin ('path') de destination du fichier.",
                "type": "string",
                "default": "",
                "minLentgth": 0,
                "maxLentgth": 256
            }
        },
        "required": ["path"],
        "additionalProperties": false
    },
    SMTP: {
        "title": "Paramètres de connexion",
        "description": "Détail des paramètres nécessaires à la bonne exécution",
        "type": "object",
        "properties": {
            "host": {
                "oneOf": [
                    {
                        "title": "Hôte",
                        "description": "Nom de la machine à atteindre",
                        "type": "string",
                        "minLentgth": 7,
                        "maxLentgth": 63,
                        "format": "hostname"
                    },
                    {
                        "title": "Hôte",
                        "description": "Adresse IP de la machine à atteindre",
                        "type": "string",
                        "minLentgth": 7,
                        "maxLentgth": 63,
                        "format": "ipv4"
                    }
                ]
            },
            "port": {
                "title": "Port de service",
                "description": "Port d'écoute du service SMTP  / IMAP (port par défaut 25)",
                "type": "integer",
                "default": 25,
                "minimum": 2,
                "maximum": 65536
            },
            "user": {
                "title": "Identifiant de connexion",
                "description": "Identifiant de connexion au service",
                "type": "string",
                "minLentgth": 8,
                "maxLentgth": 24,
                "pattern": "^(.*)$"
            },
            "password": {
                "title": "Mot de passe",
                "description": "Mot de passe de connexion",
                "type": "string",
                "minLentgth": 14,
                "maxLentgth": 30,
                "pattern": "^(.*)$"
            }
        },
        "required": [],
        "additionalProperties": false
    },
    SFTP: {
        "title": "Paramètres de connexion",
        "description": "Détail des paramètres nécessaires à la bonne exécution",
        "type": "object",
        "properties": {

        },
        "required": [],
        "additionalProperties": false
    },
    FTP: {
        "title": "Paramètres de connexion",
        "description": "Détail des paramètres nécessaires à la bonne exécution",
        "type": "object",
        "properties": {
            "host": {
                "oneOf": [
                    {
                        "title": "Hôte",
                        "description": "Nom de la machine à atteindre",
                        "type": "string",
                        "minLentgth": 7,
                        "maxLentgth": 63,
                        "format": "hostname"
                    },
                    {
                        "title": "Hôte",
                        "description": "Adresse IP de la machine à atteindre",
                        "type": "string",
                        "minLentgth": 7,
                        "maxLentgth": 63,
                        "format": "ipv4"
                    }
                ]
            },
            "port": {
                "title": "Port de service",
                "description": "Port d'écoute du service FTP (port par défaut 21)",
                "type": "integer",
                "default": 21,
                "minimum": 2,
                "maximum": 65536
            },
            "user": {
                "title": "Identifiant de connexion",
                "description": "Identifiant de connexion au service",
                "type": "string",
                "minLentgth": 8,
                "maxLentgth": 24,
                "pattern": "^(.*)$"
            },
            "password": {
                "title": "Mot de passe",
                "description": "Mot de passe de connexion",
                "type": "string",
                "minLentgth": 14,
                "maxLentgth": 30,
                "pattern": "^(.*)$"
            },
            "secure": {
                "title": "Connexion sécurisée",
                "description": "Connexion FTPS : toutes les donneées transférées seront chiffrées (SSL/TLS)",
                "type": "boolean",
                "default": false
            },
            "path_remote": {
                "title": "Dossier de destination",
                "description": "Préciser le chemin ('path') de destination du fichier une fois connecté au service.",
                "type": "string",
                "default": "",
                "minLentgth": 0,
                "maxLentgth": 256
            },
            "tmp_remote": {
                "title": "Dossier temporaire de transfert",
                "description": "",
                "type": ["string", "null"],
                "default": null,
                "minLentgth": 0,
                "maxLentgth": 256,
                "pattern": ""
            }
        },
        "required": ["host", "port", "user", "password", "secure", "path_remote"],
        "additionalProperties": false
    }
}

const mapTitlesToJsonProperties = {
    "Port de service": "port",
    "Dossier temporaire de transfert": "tmp_remote",
    "Dossier de destination": "path_remote",
    "Connexion sécurisée": "secure",
    "Mot de passe": "password",
    "Identifiant de connexion": "user",
    "Hôte": "host",
}
Object.keys(mapTitlesToJsonProperties).map(property => {
    mapTitlesToJsonProperties[mapTitlesToJsonProperties[property]] = property;
})

const getRules = (schema) => {
    let rules = []

    if (schema.hasOwnProperty("minLentgth")) {
        let rule = {
            message: ""
        };
        rule["min"] = schema.minLentgth;
        rule.message = "Au moins " + schema.minLentgth + " caractère" + (schema.minLentgth > 1 ? "s" : "") + " requis."
        rules.push(rule);
    }
    if (schema.hasOwnProperty("maxLentgth")) {
        let rule = {
            message: ""
        };
        rule["max"] = schema.maxLentgth;
        rule.message = "Maximum " + schema.maxLentgth + " caractères."
        rules.push(rule);
    }
    if (schema.hasOwnProperty("pattern")) {
        let rule = {
            message: ""
        };
        rule["pattern"] = schema.pattern;
        rule.message = "Pattern needs to follow regex : " + schema.pattern
        rules.push(rule);
    }
    if (schema.hasOwnProperty("format")) {
        let rule = {
            message: ""
        };
        if (schema.format = "ipv4") {
            rule["pattern"] = "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
            rule.message = "Format needs to be ipv4."
            rules.push(rule);
        }
        else if (schema.format = "hostname") {
            let rule = {
                message: ""
            };
            rule["pattern"] = "^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$"
            rule.message = "Format needs to be a valid hostname."
            rules.push(rule);
        }

    }

    return rules;
}

const DynamicForm = () => {
    const Selecter = ({ propetyWithOneOf, id, value = {}, onChange }) => {

        const [host, setHost] = useState("");
        const [format, setFormat] = useState(propetyWithOneOf.oneOf[0].format);

        const triggerChange = (changedValue) => {
            if (onChange) {
                onChange({
                    host,
                    format,
                    ...value,
                    ...changedValue,
                });
            }
        };
        
        const onHostChange = (e) => {
            const newHost = e.target.value


            setHost(newHost);

            triggerChange({
                host: newHost,
            });
        };
        const onFormatChange = (newFormat) => {
            if (!('Format' in value)) {
                setFormat(newFormat);
            }
            triggerChange({
                format: newFormat,
            });
        };

        const options = propetyWithOneOf.oneOf.map(elem => {
            return (
                <Option value={elem.format}>{elem.format}</Option>
            )
        })

        return (

            <span id={"id"}>
                <Tooltip title={propetyWithOneOf.oneOf.filter(x => x.format == format)[0].description} >
                    <Select
                        value={value.format || format}
                        style={{
                            width: "25%",
                            // margin: '0 2%',
                        }}
                        onChange={onFormatChange}
                        size="small"
                    >
                        {options}
                    </Select>
                    <Input
                        type="text"
                        value={value.host || host}
                        onChange={onHostChange}
                        style={{
                            width: "75%",
                        }}
                        size="small"
                    />
                </Tooltip>

            </span>
        );
    }

    const [protocol, setProtocol] = useState("SFTP");
   
    const space = "5px";
    const generateItems = (schema, required) => {
        const checkFormat = (_, value) => {
            const regexIPV4 = new RegExp("^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$");
            const regexHostname = new RegExp("^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$");
            if (value.host.length >= 7 && value.host.length <= 63 && ((value.host.match(regexIPV4) !== null && value.format == "ipv4") || (value.host.match(regexHostname) !== null && value.format == "hostname"))) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Format must be ' + value.format + ", at least 7 characters, maximum 63."));
        };
        let rules = [{
            required: required,
            message: schema.title + " requis"
        }];
        rules = [...rules, ...getRules(schema)];

        if (schema.hasOwnProperty("oneOf")) {
            return (
                <Form.Item
                    name={mapTitlesToJsonProperties[schema.oneOf[0].title]}
                    style={{ marginBottom: space, marginTop: space }}
                    label={schema.oneOf[0].title}
                    rules={[
                        {
                            validator: checkFormat,
                        },
                    ]}
                >
                    <Selecter propetyWithOneOf={schema} />
                </Form.Item>
            )
        }
        else if (Array.isArray(schema.type)) { schema.type = schema.type[0]; return generateItems(schema, false); }
        else if (schema.type === "string" && !(schema.title === "password" || schema.title === "Mot de passe")) {


            return (
                <Tooltip title={schema.description} >
                    <Form.Item
                        name={mapTitlesToJsonProperties[schema.title] || "path"}
                        rules={rules}
                        label={schema.title}
                        style={{ marginBottom: space, marginTop: space }}
                    >

                        <Input
                            value={schema.title}
                            type="text"
                            size="small"
                        />
                    </Form.Item>
                </Tooltip>
            )
        }
        else if (schema.type === "string" && (schema.title === "password" || schema.title === "Mot de passe")) {
            return (
                <Tooltip title={schema.description} >
                    <Form.Item
                        style={{ marginBottom: space, marginTop: space }}
                        name={mapTitlesToJsonProperties[schema.title]}
                        rules={[
                            {
                                required: required,
                                message: schema.title + " requis"
                            }
                        ]}
                        label={schema.title}
                    >

                        <Input.Password
                            size="small"
                        />
                    </Form.Item>
                </Tooltip>
            )
        }
        else if (schema.type === "integer") {
            return (
                <Tooltip title={schema.description} >
                    <Form.Item
                        style={{ marginBottom: space, marginTop: space }}
                        name={mapTitlesToJsonProperties[schema.title]}
                        rules={[
                            {
                                required: required,
                                message: schema.title + " requis"
                            }
                        ]}
                        label={schema.title}
                    >

                        <Input
                            // defaultValue={schema.default}
                            type="number"
                            size="small"
                            max={schema.maximum}
                            min={schema.minimum}
                        />
                    </Form.Item>
                </Tooltip>
            )
        }
        else if (schema.type === "boolean") {
            return (
                <Tooltip title={schema.description} >
                    <Form.Item
                        style={{ marginBottom: space, marginTop: space }}
                        name={mapTitlesToJsonProperties[schema.title]}
                        valuePropName="checked"
                        label={schema.title}
                    >
                        <Checkbox
                        // defaultChecked={schema.default}
                        />

                    </Form.Item>
                </Tooltip>
            )
        }
        else if (schema.type === "object") {
            let buffer = []
            for (const property in schema.properties) {
                buffer.push(<div>{generateItems(schema.properties[property], schema.required.includes(property))}</div>);
            }
            return (
                <div>
                    {buffer}
                </div>
            )
        }


    }
    const protocolOptions = Object.keys(jsonSchema).map(k => {
        return (
            <Option value={k}>{k}</Option>
        );
    })



    const onProtocolChange = (newProtocol) => {
        setProtocol(newProtocol);
    }


    return (
        <div style={{ flex: 1, display: "flex", width: "100%", flexDirection: "column", 
        // backgroundColor:"#f0f" 
        }}>

            <Tooltip title={"Type enregistrement"} >
                <Form.Item
                    style={{ marginBottom: space, marginTop: "0px" }}
                    name={"prot"}
                    label={"Type d'enregistrement"}
                    rules={[

                        {
                            required: true,
                            message: "requis"
                        }
                    ]}>

                    <Select
                        onChange={onProtocolChange}
                        size="medium"
                    >
                        {protocolOptions}
                    </Select>

                </Form.Item>
            </Tooltip>
            {generateItems(jsonSchema[protocol], true)}

        </div>
    )
}

export default DynamicForm
