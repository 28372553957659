
let actionsRechercheBordereaux = [];
let actionsRechercheRecep = [];
let actionsRoles = []
let actionsUtilisateur = []
let actionsVolumetrie = []
let actionsKPI = []
let actionsItem = []
let actionsDocumentation = []
let actionsMessagesErreur = []
let actionsTicketing = []
let actionsRetention = []
let actionsFacture = []
let modules = JSON.parse(window.localStorage.getItem('modules'))

export const ModulesProvider = () => {
    if (modules !== undefined && modules !== null)
        Object.keys(modules).forEach((module) => {
            let sousModules = modules[module].sousModule
            let moduleName = modules[module].module.libelle
            if (moduleName === 'Retention') actionsRetention = modules[module].module.actions
            if (moduleName === 'Facture') actionsFacture = modules[module].module.actions
            if (sousModules !== null && sousModules.length > 0) {
                sousModules.forEach((sousModule) => {
                    let sousModuleName = sousModule.libelle
                    switch (sousModuleName) {
                        case 'Recherche bordereaux':
                            actionsRechercheBordereaux = sousModule.actions
                            break
                        case 'Recherche récépissés':
                            actionsRechercheRecep = sousModule.actions
                            break
                        case 'item':
                            actionsItem = sousModule.actions
                            break
                        case 'Volumétrie':
                            actionsVolumetrie = sousModule.actions
                            break
                        case 'K.P.I.':
                            actionsKPI = sousModule.actions
                            break
                        case 'Rôle(s)':
                            actionsRoles = sousModule.actions
                            break
                        case 'Utilisateur(s)':
                            actionsUtilisateur = sousModule.actions
                            break
                        case 'Documentation':
                            actionsDocumentation = sousModule.actions
                            break
                        case 'Message(s) en erreur':
                            actionsMessagesErreur = sousModule.actions
                            break
                        case 'Ticketing':
                            actionsTicketing = sousModule.actions
                            break
                        default:
                            return null
                    }
                })
            }
        })
}

export const hasActionByModule = (moduleName, action) => {
    switch (moduleName) {
        case 'Recherche bordereaux':
            return hasAction(action, actionsRechercheBordereaux)
        case 'Recherche récépissés':
            return hasAction(action, actionsRechercheRecep)
        case 'item':
            return hasAction(action, actionsItem)
        case 'Volumétrie':
            return hasAction(action, actionsVolumetrie)
        case 'K.P.I.':
            return hasAction(action, actionsKPI)
        case 'Rôle(s)':
            return hasAction(action, actionsRoles)
        case 'Utilisateur(s)':
            return hasAction(action, actionsUtilisateur)
        case 'Ticketing':
            return hasAction(action, actionsTicketing)
        case 'Message(s) en erreur':
            return hasAction(action, actionsMessagesErreur)
        case 'Documentation':
            return hasAction(action, actionsDocumentation)
        case 'Retention':
            return hasAction(action, actionsRetention)
        case 'Facture':
                return hasAction(action, actionsFacture)             
        default:
            return null
    }
}
    
    const hasAction = (action,listAction) => {
        let result = false;
        Object.keys(listAction).forEach(sousAction => {
            if(action.localeCompare(listAction[sousAction].libelle) === 0){
                result = true;
            }
            })
        return result;
    }
  
  export default ModulesProvider;
