import { ArrowRightOutlined, FormOutlined, CloseOutlined, PlusOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip, Form, Select, Space, message, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'
import track, { useTracking } from 'react-tracking'
import ImplantsConnectBar from '../../components/CustomComponentsForImplants/ImplantsConnectBar'
import { getColumnSearchProps } from '../../utilities/table-utils'
import Icon from '@ant-design/icons/lib/components/Icon'
import ButtonAdd from '../../components/CustomComponentsForImplants/ButtonAdd'
import Text from 'antd/lib/typography/Text'
import CustomTableLayout from '../../components/CustomComponentsForImplants/CustomTableLayout'
import CustomPopUp from '../../components/CustomComponentsForImplants/CustomPopUp'

const colHeight = 500;
const totalWidth = 1600;
const scrollLength = 500;
const table1Width = totalWidth / 6;
const table2Width = totalWidth / 6 + 150;
const table3Width = totalWidth / 7;
const table4Width = 3 * totalWidth / 10;

const buttonAddSize = 24;

//switch to true to see the account and folder ids in database
const debug = false;

const ConfigFFST = () => {
    const success = (str) => {
        message.success(str);
    };
    const error = (str) => {
        message.error(str);
    };

    const { trackEvent } = useTracking()
    const [selectedImplant, setSelectedImplant] = useState(false);
    const [implantHost, setImplantHost] = useState(false);
    const [implantPort, setImplantPort] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [popUpType, setPopUpType] = useState("none");

    const [array1, setArray1] = useState([])
    const [array2, setArray2] = useState([])
    const [array3, setArray3] = useState([])
    const [array4, setArray4] = useState([])

    const [index1, setIndex1] = useState(-1)
    const [index2, setIndex2] = useState(-1)
    const [index3, setIndex3] = useState(-1)
    const [index4, setIndex4] = useState(-1)

    const [activity1, setActivity1] = useState(false)
    const [activity2, setActivity2] = useState(false)
    const [activity3, setActivity3] = useState(false)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');


    const editDefinition = (definition_key) => {
        setPopUpType("Delete definition");
        setIsPopUpVisible(true);
    }
    const deleteDefinitionSignal = (definition_key) => {
        setArray4([]); setIndex3(-1); setIndex4(-1);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }
    const divRef = useRef(null);


    useEffect(() => {

    }, [selectedImplant, index1, index2, index3, index4])

    const onConnectImplant = async (value) => {
        try {
            setImplantHost(value.host);
            setImplantPort(value.port);
            const res = await WidgetUIService.getAccounts(value.host, value.port)
            setArray1(res.map(x => { return { name: x.account, key: x.id } }))
            setArray2([]);
            setArray3([]);
            setArray4([]);
            setIndex1(-1);
            setIndex2(-1);
            setIndex3(-1);
            setIndex4(-1);
        }
        catch (e) {
            error(e);
        }
        return;
    }
    const onClick1 = () => { if (implantHost && implantPort) { setPopUpType("Add account"); setIsPopUpVisible(true); } }
    const onClick2 = () => { if (index1 !== -1) { setPopUpType("Add folder"); setIsPopUpVisible(true); } }
    const onClick3 = () => { if (index1 !== -1 && index2 !== -1) { setPopUpType("Add definition"); setIsPopUpVisible(true); } }
    const onClick4 = () => { if (index1 !== -1 && index2 !== -1 && index3 !== -1) { setPopUpType("Add params"); setIsPopUpVisible(true); } }
    const onClickOut = () => { setIsPopUpVisible(false); }

    const onResetAccounts = async () => {

        try {
            const res = await WidgetUIService.getAccounts(implantHost, implantPort)
            setArray1(res.map(x => { return { name: x.account, key: x.id } }))
            setIsPopUpVisible(false);
        }
        catch (e) {
            error(e);
        }

    }





    const fetchArray2 = async (accountKey) => {
        try {
            setIndex1(accountKey);
            setActivity1(true);
            setIndex2(-1);
            setIndex3(-1);
            setIndex4(-1);
            setArray3([]);
            setArray4([]);
            const res = await WidgetUIService.getFolders(accountKey, implantHost, implantPort);
            setArray2(res.map(x => { return { name: x.key_content, key: x.key_id } }));
            setActivity1(false);

        }
        catch (e) {
            error(e);
            setActivity1(false);
        }
    }
    const onResetFolders = async () => {

        try {
            const res = await WidgetUIService.getFolders(index1, implantHost, implantPort);
            setArray2(res.map(x => { return { name: x.key_content, key: x.key_id } }))
            setIsPopUpVisible(false);
        }
        catch (e) {
            error(e);
        }
    }

    const fetchArray3 = async (folderKey) => {
        try {
            setActivity2(true);
            setIndex2(folderKey);
            setIndex3(-1);
            setIndex4(-1);
            setArray4([]);
            const res = await WidgetUIService.getDefinitions(index1, folderKey, implantHost, implantPort);
            setArray3(res.map(x => { return { name: x.value_from.slice(0, 10) + " to " + ((x.value_to === null) ? "null" : x.value_to.slice(0, 10)), add: x.value_to, key: x.value_id } }))
            setActivity2(false);
        }
        catch (e) {
            error(e);
            setActivity2(false);
        }
    }
    const onResetDefinitions = async () => {

        try {
            const res = await WidgetUIService.getDefinitions(index1, index2, implantHost, implantPort);
            setArray3(res.map(x => { return { name: x.value_from.slice(0, 10) + " to " + ((x.value_to === null) ? "null" : x.value_to.slice(0, 10)), add: x.value_to, key: x.value_id } }))
            setIsPopUpVisible(false);

        } catch (e) {
            error(e);
        }
    }

    const fetchArray4 = async (definitionKey) => {
        try {
            setIndex3(definitionKey);
            setIndex4(-1);
            setActivity3(true);
            const res = await WidgetUIService.getParams(index1, index2, definitionKey, implantHost, implantPort);
            setArray4(res.map((x, i) => {
                let user = "";
                let dest = "";
                let detail = "";
                if (x.prot === "SMTP") {
                    dest += x.param.host;
                    dest += ":";
                    dest += x.param.port;
                    user += x.param.user;
                }
                if (x.prot === "FTP") {
                    dest += x.param.host;
                    dest += ":";
                    dest += x.param.port;
                    user += x.param.user;
                }
                if (x.prot === "FOLDER") {
                    dest += x.param.path_remote;
                }
                return { prot: x.prot, dest: dest, compte: user, detail: detail, protKey: i }
            }));
            setActivity3(false);
        }
        catch (e) {
            error(e);
            setActivity3(false);
        }
    }
    const onResetParams = async () => {

        try {
            const res = await WidgetUIService.getParams(index1, index2, index3, implantHost, implantPort);
            setArray4(res.map((x, i) => {
                let user = "";
                let dest = "";
                let detail = "";

                if (x.prot === "SMTP") {
                    dest += x.param.host;
                    dest += ":";
                    dest += x.param.port;
                    user += x.param.user;
                }
                if (x.prot === "FTP") {
                    dest += x.param.host;
                    dest += ":";
                    dest += x.param.port;
                    user += x.param.user;
                }
                if (x.prot === "FOLDER") {
                    dest += x.param.path_remote;
                }
                return { prot: x.prot, dest: dest, compte: user, detail: detail, protKey: i }
            }))
            setIsPopUpVisible(false);
        }
        catch (e) {
            error(e);
        }
    }

    let tableHeight = 190;
    let divider1Height = tableHeight;
    let divider2Height = tableHeight;
    let divider3Height = tableHeight;
    if (array1.length > 0 || array2.length > 0 || array3.length > 0 || array4.length > 0) {
        tableHeight = Math.max(array4.length, array3.length, array2.length, array1.length) * 37 + 37;
        divider1Height = Math.max(array2.length, array1.length) * 37 + 37;
        divider2Height = Math.max(array3.length, array2.length) * 37 + 37;
        divider3Height = Math.max(array4.length, array3.length) * 37 + 37;
    }
    tableHeight = Math.min(tableHeight, scrollLength + 40);
    divider1Height = Math.min(divider1Height, scrollLength + 40);
    divider2Height = Math.min(divider2Height, scrollLength + 40);
    divider3Height = Math.min(divider3Height, scrollLength + 40);
    tableHeight = "100%";

    const fontSize1 = 10;
    const fontSize2 = 10;
    const fontSize3 = 10;
    const fontSize4 = 10;

    const columnsTable1 = [
        {
            title: array1.length > 1 ? 'Comptes FTP/SFTP' : 'Compte FTP/SFTP',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.name.localeCompare(cell2Data.name),
                multiple: 1
            },
            // render: (text, record) => <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", height: '20px', lineHeight: '10px', justifyContent: "space-between", fontSize: fontSize1, alignContent: "center", fontWeight: index1 === record.key ? 'bold' : 'normal' }}><span>{text}</span></div>,
            render: (text, record) => <div style={{
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                height: '20px',
                lineHeight: '10px',
                justifyContent: "space-between",
                fontSize: fontSize1,
                alignContent: "center",
                fontWeight: index1 === record.key ? 'bold' : 'normal',
                position: "relative"
            }}>
                <span>{text}</span>
                {((activity1) && (index1 === record.key)) && <Spin
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    // size="small"
                    indicator={<LoadingOutlined spin />}
                />}
            </div>,
        },
        {
            title: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ButtonAdd dimensions={buttonAddSize} onClick={onClick1} /></div>,
            width: 40,
            dataIndex: 'add',
            key: 'add',
            render: (text, record) => debug && record.key
        }

    ];
    const columnsTable2 = [
        {
            id: 'dossier',
            title: 'Dossier surveillé',
            dataIndex: 'name',
            key: 'id',
            ...getColumnSearchProps('name', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.name.localeCompare(cell2Data.name),
                multiple: 1
            },
            render: (text, record) => <div style={{
                display: 'flex',
                alignItems: "center",
                height: '20px',
                lineHeight: '10px',
                fontSize: fontSize2,
                justifyContent: "space-between",
                alignContent: "center",
                fontWeight: index2 === record.key ? 'bold' : 'normal',
                position: "relative"
            }}>
                <span>{text}</span>
                {((activity2) && (index2 === record.key)) && <Spin
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    // size="small"
                    indicator={<LoadingOutlined spin />}
                />}
            </div>,
        },
        {
            title: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ButtonAdd dimensions={buttonAddSize} onClick={onClick2} /></div>,
            width: 40,
            dataIndex: 'add',
            key: 'add',
            // fixed: 'right',
            render: (text, record) => debug && record.key
            // <Button style={{ display: "flex", justifyContent: "center", alignContent: "center", width: 30 }} onClick={() => { editFolder(array2[record.key]) }}><FormOutlined style={{ fontSize: '20px' }} /></Button>,
        }
    ];
    const columnsTable3 = [
        {
            id: 'id',
            title: array3.length > 1 ? 'Définitions' : 'Définition',
            dataIndex: 'name',
            key: 'id',
            ...getColumnSearchProps('name', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.name.localeCompare(cell2Data.name),
                multiple: 1
            },
            render: (text, record) => <div style={{
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                height: '20px',
                lineHeight: '10px',
                fontSize: fontSize3,
                alignContent: "center",
                fontWeight: index3 === record.key ? 'bold' : 'normal',
                position: "relative"
            }}>
                <span>{text}</span>
                {((activity3) && (index3 === record.key)) && <Spin
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    // size="small"
                    indicator={<LoadingOutlined spin />}
                />}
            </div>,
        },
        {
            title: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ButtonAdd dimensions={buttonAddSize} onClick={onClick3} /></div>,
            width: 40,
            dataIndex: 'add',
            key: 'add',
            // fixed: 'right',
            render: (text, record) =>
                <Button disabled={(JSON.stringify(text) !== "null")} style={{ display: "flex", justifyContent: "center", alignContent: "center", width: 30 }} onClick={() => { editDefinition(record.key) }}>
                    <CloseOutlined style={{ fontSize: '20px' }} />
                </Button>
        }
    ];
    const columnsTable4 = [
        {
            id: 'prot',
            title: 'Prot',
            dataIndex: 'prot',
            key: 'protKey',
            width: table4Width / 5,
            ...getColumnSearchProps('prot', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.prot.localeCompare(cell2Data.prot),
                multiple: 1
            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index4 === record.protKey ? 'bold' : 'normal' }}>{text}</div>,
        },
        {
            id: 'dest',
            title: 'Dest',
            dataIndex: 'dest',
            key: 'destKey',
            width: table4Width / 4,
            ...getColumnSearchProps('dest', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.dest.localeCompare(cell2Data.dest),
                multiple: 2
            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index4 === record.protKey ? 'bold' : 'normal' }}>{text}</div>,
        },
        {
            id: 'compte',
            title: 'Compte',
            dataIndex: 'compte',
            key: 'compteKey',
            ...getColumnSearchProps('compte', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.compte.localeCompare(cell2Data.compte),
                multiple: 3
            },
            render: (text, record) => { return (<div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index4 === record.protKey ? 'bold' : 'normal' }}>{text}</div>) },
        },
        {
            id: 'detail',
            title: 'Détail',
            dataIndex: 'detail',
            key: 'detailKey',
            width: table4Width / 5,
            ...getColumnSearchProps('detail', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.detail.localeCompare(cell2Data.detail),
                multiple: 4
            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index4 === record.protKey ? 'bold' : 'normal' }}>{text}</div>,
        },
        {
            title: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ButtonAdd dimensions={buttonAddSize} onClick={onClick4} /></div>,
            width: 40,
            dataIndex: 'add',
            key: 'add',
            // fixed: 'right',
            // render: (text, record) => <Button style={{ display: "flex", justifyContent: "center", alignContent: "center", width: 30 }} onClick={() => { editFolder(array4[record.key]) }}><FormOutlined style={{ fontSize: '20px' }} /></Button>,
        }
    ];

    const styles = {
        table1: {
            width: table1Width,
            height: scrollLength + 50
        },
    };


    const table1 = <Table
        id="table-comptes"
        onRow={(record) => ({
            onClick: () => { if (record.key !== index1) { fetchArray2(record.key); } }
        })}
        pagination={false}

        bordered={debug}
        scroll={{
            // x: 100,
            y: scrollLength,
        }}
        columns={columnsTable1}
        size="small"
        dataSource={array1}
        style={styles.table1}
    />

    const table2 = <Table
        id="table-dossiers"
        onRow={(record) => ({
            onClick: () => { if (record.key !== index2) { fetchArray3(record.key); } }
        })}
        pagination={false}
        bordered={debug}
        scroll={{
            // x: 200,
            y: scrollLength,
        }}
        columns={columnsTable2}
        size="small"
        dataSource={array2}
        style={{ width: table2Width, height: scrollLength + 50 }}
    />

    const table3 = <Table
        id="table-definitions"
        onRow={(record) => ({
            onClick: () => { if (record.key !== index3) { fetchArray4(record.key); } }
        })}
        pagination={false}
        bordered={false}
        scroll={{
            y: scrollLength,
        }}
        columns={columnsTable3}
        size="small"
        dataSource={array3}
        style={{ width: table3Width, height: scrollLength + 50 }}
    />

    const table4 = <Table
        id="table-param"
        sorter={true}
        onRow={(record) => ({
            onClick: () => { if (record.protKey !== index4) { setIndex4(record.protKey); } }
        })}
        pagination={false}
        bordered
        scroll={{
            y: scrollLength,
        }}
        columns={columnsTable4}
        size="small"
        dataSource={array4}
        style={{ width: table4Width, height: scrollLength + 50 }}
    />

    const tables = [table1, table2, table3, table4]


    return (

        <div style={{ overflowY: "hidden", position: 'relative' }}>

            <Card>
                <ImplantsConnectBar onConnect={onConnectImplant} />
            </Card>
            <div style={{ height: 10 }}></div>
            <div style={{ backgroundColor: "#3CB371", height: 2 }}></div>
            <div style={{ height: 10 }}></div>

            <div className="ant-row" style={{ flex: 1, justifyContent: "flex-start" }}>

                <CustomTableLayout items={tables} dimensions={[table1Width, table2Width, table3Width, table4Width]} rowHeight={scrollLength + 40} />

            </div>

            <div style={{ height: 10 }}></div>
            <div style={{ backgroundColor: "#3CB371", height: 2 }}></div>
            <div style={{ height: 10, }}></div>

            {(isPopUpVisible) && <div style={{ position: 'absolute', display: "flex", top: 50, left: 0, height: "100%", width: "100%" }}>
                <CustomPopUp
                    clickOut={onClickOut}
                    height={scrollLength}
                    type={popUpType}
                    implantHost={implantHost}
                    implantPort={implantPort}
                    index1={index1}
                    index2={index2}
                    index3={index3}
                    index4={index4}
                    resetAccounts={onResetAccounts}
                    resetFolders={onResetFolders}
                    resetDefinitions={onResetDefinitions}
                    resetParams={onResetParams}
                    deleteDefinitionSignal={deleteDefinitionSignal} />
            </div>}
        </div>

    )
}

export default ConfigFFST