import { ApiOutlined, ArrowRightOutlined, LoadingOutlined, MoreOutlined, SearchOutlined, ThunderboltOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip, Form, Space,Spin } from 'antd'
import { Select } from 'antd';
import React, { useEffect, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'

const { Option } = Select;

const ImplantsConnectBar = ({ onConnect }) => {
    const [selectedImplant, setSelectedImplant] = useState(-1)
    const [implants, setImplants] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isIniting, setIsIniting] = useState(false);
    const [connected, setConnected] = useState(-2);
    // let implant = selectedImplant;

    useEffect(() => {
        setIsIniting(true);
        WidgetUIService.getImplants(localStorage.getItem('client'))
            .then((i) => {
                return i.data;
            })
            .then((implants) => {
                setImplants(implants);
                setIsIniting(false);
            });

    }, [])

    const onImplantChange = (value) => {
        //  implant = value;
         setSelectedImplant(value);
         console.log("implant changed to : "+value);
         console.log("connected : "+connected);
         }

    const connectImplant = async () => {
        setIsLoading(true);
        if (selectedImplant === -1) {
            setSelectedImplant(0);
            await onConnect(implants[0]);
            setIsLoading(false);
            setConnected(0);
        }
        else {
            await onConnect(implants[selectedImplant]);
            setIsLoading(false);
            setConnected(selectedImplant);
        }

    }

    const implantsOptions = implants.map((k, i) => {
        return (
            <Option value={i}>{k.infosImplant}</Option>
        );
    })

    return (

        <>
            {(isIniting)?<div style={{ flex: 1, display: "flex", maxWidth: 1600, justifyContent: "center" }}><Spin
                                size="large"
                                indicator={<LoadingOutlined spin />}
                            // style={{backgroundColor:"#f0f"}} 
                            /></div>:
            
            (implants.length !== 0) &&
                <div
                    //  className="ant-row"
                    style={{ flex: 1, display: "flex", maxWidth: 1600, justifyContent: "center" }}>
                    <Form
                        method="GET"
                        name="select-implant"
                        layout="inline"
                        onFinish={connectImplant}
                        initialValues={
                            {
                                remember: true,
                                "implant": implants[0].infosImplant,
                            }
                        }
                        // labelCol={{
                        //     span: 6,
                        // }}
                        style={{
                            maxWidth: 1200,
                            margin: "auto",
                        }}
                    >
                        <Form.Item
                            name={"implant"}
                            label={"Implant"}
                            style={{
                                display: "flex",
                                alignItems:"center",
                            }}
                            
                        >
                            <Select
                                style={{ width: 200 }}
                                onChange={onImplantChange}
                                size="large"
                            >
                                {implantsOptions}
                            </Select>

                        </Form.Item>
                        <Form.Item
                        // wrapperCol={{
                        //     offset: 6
                        // }}
                        >
                            <Button
                                type="primary"
                                id="btn-submit"
                                style={{ backgroundColor: '#3CB371', color: 'white' }}
                                htmlType="submit"
                                block
                                size="large"
                                icon={!(connected===selectedImplant)?<ApiOutlined />:<ThunderboltOutlined />}
                            >
                                Connexion
                            </Button>
                        </Form.Item>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            width: 30
                            // backgroundColor: "#f00"
                        }}>
                            {(isLoading) && <Spin
                                size="large"
                                indicator={<LoadingOutlined spin />}
                            // style={{backgroundColor:"#f0f"}} 
                            />}
                        </div>
                    </Form>

                </div>}
        </>

    )
}

export default ImplantsConnectBar