import { ArrowRightOutlined, CheckCircleTwoTone, CloseSquareTwoTone, FormOutlined, MoreOutlined, PlusOutlined, SearchOutlined, WarningTwoTone } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Dropdown, Divider, List, Menu, Row, Table, Tooltip, Form, Select, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import WidgetUIService from 'services/WidgetUIService'
import ImplantsConnectBar from '../../components/CustomComponentsForImplants/ImplantsConnectBar'
import { getColumnSearchProps } from '../../utilities/table-utils'
import Cookies from 'js-cookie'
import ImplantsFilterBar from '../../components/CustomComponentsForImplants/ImplantsFilterBar'
import Text from 'antd/lib/typography/Text'
import ButtonAdd from '../../components/CustomComponentsForImplants/ButtonAdd'
import CustomTableLayout from 'components/CustomComponentsForImplants/CustomTableLayout'
import TransfertConfigCard from 'components/CustomComponentsForImplants/TransfertConfigCard'

const { Option } = Select;

const totalWidth = 1600;
const scrollLength = 500;
const table4Width = 700;


const MonitorFFST = () => {

    const success = (str) => {
        message.success(str);
    };
    const error = (str) => {
        message.error(str);
    };

    const nbRowPreference = Cookies.get('nbRow') !== undefined ? Cookies.get('nbRow') : 10
    const [nbRow, setNbRow] = useState(nbRowPreference)
    const [currentPage, setCurrentPage] = useState(1)

    const [implantHost, setImplantHost] = useState(false);
    const [implantPort, setImplantPort] = useState(false); const [filter, setFilter] = useState({ account: "", folder: "", config_from: "", config_to: "" })

    const [history, setHistory] = useState([]);
    const [detail, setDetail] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [index, setIndex] = useState(-1)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const delay = async (ms) => {
        return new Promise((resolve) =>
            setTimeout(resolve, ms));
    };

    const handleDetail = async () => {
        await delay(100);
        setShowDetail(true);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }
    //search by name in an array of object, return id property, -1 if not found.
    const findId = (name, arr) => {
        for (let i = 0; i < arr.length; i++) {
            const value = arr[i].value;
            if (value.toLowerCase() === name.toLowerCase()) {
                return arr[i].id;
            }
        }
        return -1;
    }

    const onConnectImplant = async (value) => {
        try {
            setImplantHost(value.host);
            setImplantPort(value.port);
            const res = await WidgetUIService.getHistory(20, value.host, value.port)

            const hisParsed = [];
            res.map((x, i) => {
                const matchingJournalId = hisParsed.filter(y => y.journal_id === x.journal_id);
                if (matchingJournalId.length === 0) {
                    hisParsed.push({
                        journal_id: x.journal_id,
                        account_id: x.account_id,
                        account_name: x.account_name,
                        key_id: x.key_id,
                        key_content: x.key_content,
                        journal_filename: x.journal_filename,
                        journal_filedir: x.journal_filedir,
                        journal_filesize: x.journal_filesize,
                        journal_time: x.journal_time,
                        datas: [
                            {
                                flow_error: x.flow_error,
                                configfileout: x.configfileout,
                                transfert_config: x.transfert_config,
                                transfert_statut: x.transfert_statut,
                                transfert_log: x.transfert_log
                            }
                        ]

                    })
                }
                else {
                    matchingJournalId[0].datas.push({
                        flow_error: x.flow_error,
                        configfileout: x.configfileout,
                        transfert_config: x.transfert_config,
                        transfert_statut: x.transfert_statut,
                        transfert_log: x.transfert_log
                    });

                }

            })
            setHistory(hisParsed);
            setIndex(-1);
            setShowDetail(false);
            setDetail([]);
        }
        catch (e) {
            error(e);
        }
        return;

    }
    const dateInS = (date) => {
        const TS = new Date(date);
        return TS.getTime();
    }
    const returnStatutIcon = (text, record) => {
        const dimensions = index === record.key ? 35 : 30;
        switch (text) {
            case "K0":
                return <CloseSquareTwoTone
                    twoToneColor="#cc0001"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
            case "OK":
                return <CheckCircleTwoTone
                    twoToneColor="#009a00"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
            case "OKKO":
                return <WarningTwoTone
                    twoToneColor="#ffcc00
"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
            case "null":
                return "N/A";
            default:
                return <WarningTwoTone
                    twoToneColor="#ffcc00
"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
        }
    }
    const returnBoolIcon = (text) => {
        const dimensions = 30;
        switch (text) {
            case 0:
                return <CloseSquareTwoTone
                    twoToneColor="#cc0001"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
            case 1:
                return <CheckCircleTwoTone
                    twoToneColor="#009a00"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
            case "null":
                return "N/A";
            default:
                return <WarningTwoTone
                    twoToneColor="#ffcc00
"
                    style={{
                        fontSize: dimensions + 'px'
                    }}
                />;
        }
    }

    const parseHistory = (his) => {

        return (his.map((x, i) => {
            let counterOK = 0;
            let counterKO = 0;
            x.datas.map(y => {
                if (y.transfert_statut === "K0") { counterKO++; }
                if (y.transfert_statut === "OK") { counterOK++; }
            })

            let statut = "null";

            if (counterOK === x.datas.length) { statut = "OK"; }
            else if (counterKO === x.datas.length) { statut = "K0"; }
            else if (counterKO > 0 && counterOK > 0) { statut = "OKKO"; }
            return {
                date: x.journal_time.slice(0, 10),
                compte: x.account_name,
                fichier: x.journal_filename,
                statut: statut,
                key: i
            }
        }))
    }
    const onReset = async () => { if (implantHost && implantPort) { await onConnectImplant({ host: implantHost, port: implantPort }) }; return; }

    const onFilter = async (value) => {

        try {
            let accountId = -1;
            if (!value.account) { accountId = null }
            else { accountId = findId(value.account, value.initOptionsAccount); }

            let folderId = -1;
            if (!value.folder) { folderId = null }
            else { folderId = findId(value.folder, value.initOptionsFolder); }

            if (implantHost && implantPort) {
                console.log("filtering")
                if (accountId === -1 || folderId === -1) {
                    setHistory([]);
                    setIndex(-1);
                }
                else {
                    let res = await WidgetUIService.getFilteredHistory(20,
                        accountId,
                        folderId,
                        implantHost,
                        implantPort
                    )
                    if (!value.config_from && !value.config_to) { }
                    else {

                        if (value.config_from) {
                            const TSFrom = dateInS(value.config_from);
                            res = res.filter(x => {
                                const TSjournal = dateInS(x.journal_time.slice(0, 10));
                                if (TSjournal >= TSFrom) { return true; }
                                else { return false; }
                            })
                        }
                        if (value.config_to) {
                            const TSTo = dateInS(value.config_to);
                            res = res.filter(x => {
                                const TSjournal = dateInS(x.journal_time.slice(0, 10));
                                if (TSjournal <= TSTo) { return true; }
                                else { return false; }
                            })
                        }
                    }
                    const hisParsed = [];
                    res.map((x, i) => {
                        const matchingJournalId = hisParsed.filter(y => y.journal_id === x.journal_id);
                        if (matchingJournalId.length === 0) {
                            hisParsed.push({
                                journal_id: x.journal_id,
                                account_id: x.account_id,
                                account_name: x.account_name,
                                key_id: x.key_id,
                                key_content: x.key_content,
                                journal_filename: x.journal_filename,
                                journal_filedir: x.journal_filedir,
                                journal_filesize: x.journal_filesize,
                                journal_time: x.journal_time,
                                datas: [
                                    {
                                        flow_error: x.flow_error,
                                        configfileout: x.configfileout,
                                        transfert_config: x.transfert_config,
                                        transfert_statut: x.transfert_statut,
                                        transfert_log: x.transfert_log
                                    }
                                ]

                            })
                        }
                        else {
                            matchingJournalId[0].datas.push({
                                flow_error: x.flow_error,
                                configfileout: x.configfileout,
                                transfert_config: x.transfert_config,
                                transfert_statut: x.transfert_statut,
                                transfert_log: x.transfert_log
                            });

                        }
                    })
                    setHistory(hisParsed);
                    setIndex(-1);
                    setShowDetail(false);
                    setDetail([]);
                }
            }
        }
        catch (e) {
            error(e);
        }
        return;
    }


    let tableHeight = 190;
    let divider1Height = tableHeight;
    let divider2Height = tableHeight;
    let divider3Height = tableHeight;

    tableHeight = Math.min(tableHeight, scrollLength + 40);
    divider1Height = Math.min(divider1Height, scrollLength + 40);
    divider2Height = Math.min(divider2Height, scrollLength + 40);
    divider3Height = Math.min(divider3Height, scrollLength + 40);
    tableHeight = "100%";

    const fontSize1 = 10;
    const fontSize2 = 10;
    const fontSize3 = 10;
    const fontSize4 = 10;
    const table1Width = 600;
    const table2Width = 500;

    const columnsTable1 = [
        {
            id: 'date',
            title: 'Date',
            dataIndex: 'date',
            key: 'dateKey',
            width: 50,
            ...getColumnSearchProps('date', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.date.localeCompare(cell2Data.date),

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index === record.key ? 'bold' : 'normal' }}>{text}</div>,
        },
        {
            id: 'compte',
            title: 'Compte',
            dataIndex: 'compte',
            key: 'compteKey',
            width: 80,
            ...getColumnSearchProps('compte', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.compte.localeCompare(cell2Data.compte),

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index === record.key ? 'bold' : 'normal' }}>{text}</div>,
        },
        {
            id: 'fichier',
            title: 'Fichier',
            dataIndex: 'fichier',
            key: 'fichierKey',
            width: 150,
            ...getColumnSearchProps('fichier', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.fichier.localeCompare(cell2Data.fichier),

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: index === record.key ? 'bold' : 'normal' }}>{text}</div>,
        },
        ,
        {
            id: 'statut',
            title: 'Statut',
            dataIndex: 'statut',
            key: 'statutKey',
            width: 50,
            ...getColumnSearchProps('statut', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.statut.localeCompare(cell2Data.statut)

            },
            render: returnStatutIcon,
        },
        {
            id: 'detail',
            title: 'Détail',
            dataIndex: 'detail',
            width: 35,
            render: (text, record) => (
                <div className="btn-wrap">
                    <Button
                        id="btn-detail"
                        style={{
                            height: index === record.key ? 35 : 30 + 'px'
                        }}
                        onClick={() => {
                            // setIndexDetails(record.key);
                            // setShowDetail(true);
                            handleDetail();
                            setDetail(history[record.key].datas.map((x, i) => { return { ...x, key: i } }));
                        }}
                    >
                        {/* <a href="#details"> */}
                        <SearchOutlined
                            style={{
                                fontSize: index === record.key ? 15 : 10 + 'px'
                            }}
                        />
                        {/* </a> */}
                    </Button>
                </div>
            )
        }
    ]
    const columnsTable2 = [
        {
            id: 'flow_error',
            title: 'Flux d\'erreur',
            dataIndex: 'flow_error',
            key: 'flow_errorKey',
            width: 60,
            ...getColumnSearchProps('flow_error', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.flow_error.localeCompare(cell2Data.flow_error),

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>
                {returnBoolIcon(text)}
            </div>,
        },
        {
            id: 'configfileout',
            title: 'configfileout',
            dataIndex: 'configfileout',
            key: 'configfileoutKey',
            width: table4Width / 8,
            ...getColumnSearchProps('configfileout', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.configfileout.localeCompare(cell2Data.configfileout),

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>
                {returnBoolIcon(text)}
            </div>,
        },
        {
            id: 'transfert_config',
            title: 'transfert_config',
            dataIndex: 'transfert_config',
            key: 'transfert_configKey',
            width: 100,
            ...getColumnSearchProps('transfert_config', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.transfert_config.localeCompare(cell2Data.transfert_config),

            },
            // render: (text, record) =>
            //     <div style={{ height: '160px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>
            //         {
            //             (text) &&
            //             JSON.parse(text).protocole
            //         }
            //         <br />
            //         <br />
            //         {
            //             (text) &&
            //             (JSON.parse(text).param) &&
            //             printParams(JSON.parse(text).param)
            //         }
            //     </div>
            render: (text, record) =>
                <div style={{ flex: 1, lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>
                    {(text) && JSON.parse(text) && <TransfertConfigCard jsonValues={JSON.parse(text)} />}
                </div>
        },

        // {
        //     id: 'transfert_log',
        //     title: 'transfert_log',
        //     dataIndex: 'transfert_log',
        //     key: 'transfert_logKey',
        //     width: table4Width / 8,
        //     ...getColumnSearchProps('transfert_log', searchText, searchedColumn, handleSearch, handleReset),
        //     sorter: {
        //         compare: (cell1Data, cell2Data) => cell1Data.transfert_log.localeCompare(cell2Data.transfert_log),

        //     },
        //     render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>{text}</div>,
        // },
        {
            id: 'transfert_statut',
            title: 'Statut',
            dataIndex: 'transfert_statut',
            key: 'statutKey',
            width: 40,
            ...getColumnSearchProps('transfert_statut', searchText, searchedColumn, handleSearch, handleReset),
            sorter: {
                compare: (cell1Data, cell2Data) => cell1Data.transfert_statut.localeCompare(cell2Data.transfert_statut)

            },
            render: (text, record) => <div style={{ height: '20px', lineHeight: '10px', fontSize: fontSize4, alignContent: "center", fontWeight: 'normal' }}>
                {returnBoolIcon(text === "OK" ? 1 : text === "K0" ? 0 : "null")}
            </div>,
        }
    ];
    const table1 = <Table
        id="table-param"
        sorter={true}
        onRow={(record) => ({
            onClick: () => {
                if (record.key !== index) {
                    setIndex(record.key);
                    setShowDetail(false);
                }
            }
        })}
        pagination={{
            current: currentPage,
            pageSize: nbRow,
            position: ['downRight'],
            onChange: (page) => {
                if (currentPage) setCurrentPage(page)
            }
        }}
        bordered
        scroll={{
            y: scrollLength,
        }}
        columns={columnsTable1}
        size="small"
        dataSource={parseHistory(history)}
        style={{ width: table1Width }}
    />
    const table2 = <Table
        id="table-param2"
        sorter={true}
        pagination={false}
        bordered
        scroll={{
            y: scrollLength,
        }}
        columns={columnsTable2}
        size="small"
        dataSource={detail}
        style={{ width: table2Width }}
    />

    const tables = [table1];
    const dimensions = [table1Width]
    if (showDetail) {
        tables.push(table2);
        dimensions.push(table2Width);
    }

    return (

        <div style={{ overflowY: "hidden", position: 'relative' }}>
            <Card>
                <ImplantsConnectBar
                    onConnect={onConnectImplant}
                />
            </Card>
            <div style={{ height: 10 }}></div>
            <div style={{ backgroundColor: "#3CB371", height: 2 }}></div>
            <div style={{ height: 10 }}></div>
            <Card>
                <ImplantsFilterBar
                    onFilter={onFilter}
                    onReset={onReset}
                    host={implantHost}
                    port={implantPort}
                />
            </Card>
            <div style={{ height: 10 }}></div>
            <div style={{ backgroundColor: "#3CB371", height: 2 }}></div>
            <div style={{ height: 10 }}></div>

            <Card>
                <Row gutter={{ xs: 1, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row">
                        <div>Nombre de lignes: </div>
                    </Col>
                    <Col className="gutter-row">
                        <Select
                            style={{ width: '60px' }}
                            defaultValue={nbRow}
                            onChange={(e) => {
                                Cookies.set('nbRow', e)
                                setNbRow(e)
                            }}
                        >
                            <Option value="5">5</Option>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                        </Select>
                    </Col>
                </Row>
            </Card>
            <Card>


                <div className="ant-row" style={{ flex: 1, justifyContent: "flex-start" }}>

                    <CustomTableLayout items={tables} dimensions={dimensions} rowHeight={scrollLength + 40} />

                </div>
            </Card>
            <div style={{ height: 10 }}></div>
            <div style={{ backgroundColor: "#3CB371", height: 2 }}></div>
            <div style={{ height: 10 }}></div>
        </div>

    )
}

export default MonitorFFST